.wpo-location-pg-section {
    padding-bottom: 24px;


    .wpo-location-title {
        max-width: 440px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 50px;

        @media(max-width:767px) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 35px;
            font-size: calc-rem-value(35);
            font-weight: 700;
            margin-bottom: 20px;

            @media(max-width:575px) {
                font-size: 25px;
                font-size: calc-rem-value(25);
            }
        }
    }

    form input,
    form select,
    form textarea {
        background: transparent;
        width: 100%;
        height: 50px;
        border: 1px solid transparent;
        border-radius: 0px;
        box-shadow: none !important;
        padding-left: 25px;
        border: 1px solid #ebebeb;

        @include media-query(991px) {
            height: 45px;
        }

        &:focus {
            border-color: $theme-primary-color;
            background: transparent;
        }
    }

    form textarea {
        height: 180px;
        padding-top: 15px;
    }

    form {
        
        overflow: hidden;

        @include placeholder-style(#9d9c9c, 15px, normal);

        select {
            display: inline-block;
            color: #a9a9a9;
            cursor: pointer;
            opacity: 1;
            padding: 6px 25px;
            font-size: 15px;
            font-size: calc-rem-value(15);
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
            position: relative;

            &:focus {
                background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
            }
        }

        .submit-area {
            text-align: center;
            width: 100%;
            margin-bottom: 40px;
            margin-left: 0;

            @include media-query(767px) {
                margin-bottom: 0;
            }

            .theme-btn {
                border-radius: 0px;
                font-family: $base-font;
                font-size: 16px;

                &:after {
                    border-radius: 0px;
                }
            }
        }

        .form-field {
            margin-bottom: 20px;
        }

        .errorMessage {
            margin-top: 3px;
            color: red;
        }
    }

    form>div {
        margin: 0 15px 25px;

        @include media-query(600px) {
            width: calc(100% - 25px);
            float: none;
        }
    }

    form .fullwidth {
        width: calc(100% - 25px);
        float: none;
        clear: both;
    }


    .wpo-location-form-area {
        padding: 50px;
        background: $white;
        box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
        z-index: 99;

        @media(max-width:767px) {
            padding: 30px;
            padding-top: 50px;
        }
    }
}