
select#lang{
    width: 130px;
    text-align: center;
}
img.flag{
    width: 30px;
    height: 20px;
}
#lang{
    margin-top:0;
}
@media(max-width:991px){
    #lang{
        width:85px!important;
    }
}
