.outletContainer {
   
}

.outletContainerFixed {
    padding-top: 0px;
}

@media only screen and (min-width: 900px) {
    .outletContainerFixed {
        padding-top: 140px;
    }
}