.icon{
    font-size: medium;
    width: 2em;
}
p.titre{
    font-size: 16px;
    color: black;
}
.menu{
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 8px 0px 8px 0px;
}
.menu:hover{
    background-color: rgba(0, 0, 0, 0.1);
}
.faWarning{
    font-size: 5em;
}

.search{
    /* background-color: red; */
    margin-top: -140;
}