/*--------------------------------------------------------------
18. wpo-reservation-pg-section
--------------------------------------------------------------*/
.wpo-reservation-pg-section {
  padding-bottom: 0;
  margin-bottom: 50px;

  .wpo-reservation-title {
    max-width: 440px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  form input:not([type="radio"]),
  form select,
  form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 24px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
      height: 45px;
    }

    &:focus {
      border-color: $theme-primary-color;
      background: transparent;
    }
  }

  /* Style the up and down arrows */

  form textarea {
    height: 150px !important;
    padding-top: 15px;
  }

  form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
      display: inline-block;
      color: #a9a9a9;
      cursor: pointer;
      opacity: 1;
      padding: 6px 25px;
      font-size: 15px;
      font-size: calc-rem-value(15);
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
      position: relative;

      &:focus {
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
      }
    }

    .submit-area {
      text-align: center;
      width: 100%;
      margin-bottom: 40px;
      margin-left: 0;

      @include media-query(767px) {
        margin-bottom: 0;
      }

      .theme-btn {
        border-radius: 0px;
        font-family: $base-font;
        font-size: 16px;

        &:after {
          border-radius: 0px;
        }
      }
    }

    .form-field {
      margin-bottom: 20px;
    }

    .errorMessage {
      margin-top: 3px;
      color: red;
    }
  }

  form > div {
    margin: 0 15px 25px;

    @include media-query(600px) {
      width: calc(100% - 25px);
      float: none;
    }
  }

  form .fullwidth {
    width: calc(100% - 25px);
    float: none;
    clear: both;
  }

  .wpo-reservation-form-area {
    padding: 50px;
    background: $white;
    box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
    padding-bottom: 0;
    position: relative;
    z-index: 99;

    @media (max-width: 767px) {
      padding: 30px;
      padding-top: 50px;
    }
  }

  .wpo-contact-widget {
    border: 0;
    background: url(../../images/bg-2.jpg) no-repeat center center;
    position: relative;
    background-size: cover;
    padding: 20px 18px;
    z-index: 1;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(48, 52, 67, 0.9);
      content: "";
      z-index: -1;
    }

    h2 {
      font-size: 25px;
      font-weight: 700;
      text-align: left;
      color: $white;
      margin-bottom: 20px;

      &::before {
        background: $white;
      }
    }

    p {
      color: $white;
      font-size: 15px;
    }

    a {
      display: inline-block;
      padding: 10px 20px;
      border: 1px solid $white;
      font-size: 18px;
      color: $white;
      padding-right: 90px;
      position: relative;
      margin-top: 10px;

      &::before {
        font-family: "themify";
        content: "\e628";
        font-size: 18px;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}
