a.hotelType{
    user-select: none;
}
button.btn{
    font-size: 15px;
    text-align: center;
    border: 3px solid transparent;
    font-weight: bold;
}
button.btn:focus{
    box-shadow: none;
}
button.btn:hover{
    border-bottom: 3px solid rgb(240,82,63) ;
}
