

.wpo-breadcrumb-area {
    position: relative;
    z-index: 1;
    height: 500px;
	width: 100%;
	object-fit: cover;

	@media(max-width: 767px) {
		height: 250px;
	}
	
	@media(max-width: 450px) {
		height: 150px;
	}
		
	&:before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// background: rgba(13, 59, 93, 0.7);
		content: "";
		z-index: -1;
	}
	.wpo-breadcrumb-wrap {
		text-align: center;

		h2{
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			margin-top: 10px;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ul{
			li{
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-size: 20px;

				@media(max-width:767px){
					font-size: 18px;
				}

				&:after{
					content: "/";
					position: relative;
					left: 7px;
				}

				&:last-child{
					span{
						color: #cbd4fd;
					}
					&:after{
						display: none;
					}
				}

				a{
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover{
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}



.wpo-about-section.wpo-about-section-s2{
	padding-top: 170px;
}