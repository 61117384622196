.inputs-detail {
  display: inline-flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.input-detail {
  width: 10% !important;
}

@media only screen and (max-width: 600px) {
  .inputs-detail {
    align-items: flex-end;
    flex-direction: column;
  }

  .input-detail {
    width: 50% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input-detail {
    width: 20% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .input-detail {
    width: 20% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .inputs-detail {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .input-detail {
    width: 10% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .inputs-detail {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .input-detail {
    width: 10% !important;
  }
}
